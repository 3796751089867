<template >
  <div>
    <b-row>
      <b-col>
        <h2>Liste des clients</h2>
      </b-col>
      <b-col cols="auto" class="ml-auto mb-1">
        <b-btn v-b-toggle.sidebar-right>Ajouter nouveau</b-btn>
        <b-sidebar id="sidebar-right" right shadow>
          <div class="px-3 py-2">
            <div>
              <h2 class="text-center mb-2">Ajouter un client</h2>
            </div>

            <form>
              <b-form-group
                id="fieldset-1"
                label="Email du client"
                label-for="input-1"
              >
                <b-form-input id="input-1" trim placeholder="Email">
                </b-form-input>
              </b-form-group>

              <b-form-group
                id="fieldset-1"
                label="Nom du client"
                label-for="input-1"
              >
                <b-form-input id="input-1" trim placeholder="Nom">
                </b-form-input>
              </b-form-group>
              <b-form-group
                id="fieldset-1"
                label="Téléphone du client"
                label-for="input-1"
              >
                <b-form-input id="input-1" trim placeholder="Téléphone">
                </b-form-input>
              </b-form-group>
              <b-form-group
                id="fieldset-1"
                label="Addresse du client"
                label-for="input-1"
              >
                <b-form-input id="input-1" trim placeholder="Addresse">
                </b-form-input>
              </b-form-group>

              <div class="text-center">
                <b-button>Enrégistrer</b-button>
              </div>
            </form>
          </div>
        </b-sidebar>
      </b-col>
    </b-row>

    <b-row>
      <b-table
        responsive
        :fields="fields"
        :items="customers"
        selectable
        select-mode="single"
        selected-variant="primary"
        :per-page="perPage"
        :current-page="currentPage"
      >
      </b-table>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="customers.length"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "id",
          label: "N°",
          thStyle: { width: "1px" },
        },

        {
          key: "name",
          label: "Nom",
          thStyle: { width: 0 },
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          thStyle: { width: 0 },
        },
        {
          key: "phone",
          label: "Téléphone",
          thStyle: { width: 0 },
        },
        {
          key: "address",
          label: "Addresse",
          thStyle: { width: 0 },
        },
      ],

      customers: [
        {
          id: 1,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },

        {
          id: 2,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },
        {
          id: 3,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },
        {
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },
        {
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },{
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },{
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },{
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },{
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },{
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },{
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },{
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },{
          id: 4,
          name: "Sobetra S",
          email: "sobetra@gmail.com",
          phone: "67084056",
          address: "Cotonou - Bénin",
        },
      ],
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/priority-box.scss";

%scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: #f8f4f4;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background-color: silver;
  }
}

.tab-nav-class {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 10%);
  flex-wrap: nowrap;
  align-items: center;
  overflow-x: scroll;
  white-space: nowrap;
  @extend %scrollbar;
}

.b-table-sticky-header {
  max-height: calc(100vh - 235px) !important;
}

.card-body {
  height: calc(100vh - 95px);
  padding: 1.5rem;
  overflow-y: hidden;
  overflow-x: scroll;
}

.popo {
  height: 370px;
  overflow-y: scroll;
}

.popover-div {
  max-height: calc(100vh / 4);
  overflow-y: scroll;
  @extend %scrollbar;
}

.table-responsive {
  @extend %scrollbar;
}

.flat-form-control {
  border: none;
  cursor: pointer;
}

.task-title {
  max-height: 40px;
  overflow-y: auto;
}
</style>